<template>
  <div>
    <FileUpload  :name="'audio'" :url="getUploadUrl()"
                 @upload="afterUpload"
                 @before-send="beforeSend"
                 :fileLimit="filelimits"
                 accept="audio/*" :maxFileSize="maxFileSize">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
    <div v-if="value" class="el-upload-list--picture-card">
      <div class="el-upload-list__item" :style="{width:previewWidth,height:previewHeight}">
        <audio controls style="width:100%">
          <source :src="getUrl()+value">
          Your browser does not support the audio tag.
        </audio>
      </div>
    </div>
  </div>



</template>

<script>

import {Upload, Dialog, Button} from 'element-ui';
import FileUpload from 'primevue/fileupload';


export default {

  name: "PrimeAudioUploader",
  props: {
    elementNum:{
      default:1
    },
    maxFileSize:{
      default:1000000
    },
    buttonTitle: {
      default: "Click To Upload"
    },
    value: {},
    previewWidth: {
      type: String,
      default:"240px"
    },
    previewHeight: {
      type: String,
      default:"auto"
    },
  },

  components: {
    [Upload.name]: Upload,
    [Dialog.name]: Dialog,
    [Button.name]: Button,
    FileUpload,
  },

  data() {
    return {
      dialogVideoUrl: '',
      filelimits:1,
      disabled: false,
      dialogVisible: false
    };
  },
  mounted() {
    this.filelimits = this.elementNum;
  },
  methods: {
    beforeSend: function (event) {
      this.filelimits++;
      event.xhr.setRequestHeader('Authorization', "Bearer " + this.$store.getters["auth/token"]);
    },
    afterUpload: function (event) {
      let resp = JSON.parse(event.xhr.response);
      this.$emit("input", resp.data.url);
    },
    handleVideoCardPreview() {
      this.dialogVideoUrl = process.env.VUE_APP_SERVER_IMAGE_URL + this.value;
      this.dialogVisible = true;
    },
    handleRemove(x=1000) {
      this.$emit('input', "");
    },
    getUrl() {
      return process.env.VUE_APP_SERVER_IMAGE_URL;
    },

    getUploadUrl(){
        return process.env.VUE_APP_SERVER_IMAGE_URL+'api/files/upload-audio';
    },
    closeVideoPreview(){
      var vid = document.getElementById("video-preview");
      vid.pause();
      this.dialogVideoUrl = null;
    }
  },
}
</script>
<style scoped>
.el-upload-list__item {
  margin-top: 10px;
}

</style>
